<script lang="ts" setup>
import {defineEmits} from "@vue/runtime-core";

const route = useRoute();
const localePath = useLocalePath();

const emit = defineEmits<{
  (e: 'hoverEnter', id: string): void,
  (e: 'hoverExit', id: string): void
}>()

const props = defineProps({
  navRoute: {type: String, required: true},
  name: {type: String, required: true}
});

function emitEnter(): void {
  emit('hoverEnter', props.navRoute);
}

function emitExit(): void {
  emit('hoverExit', props.name);
}
</script>

<template>
  <div class="nav-button-content" role="button"
       @mouseenter="emitEnter" @mouseleave="emitExit">
    <nuxt-link :to="localePath(navRoute)" class="nav-btn" :class="route.path.includes(navRoute) ? 'hovered' : ''">{{
        name
      }}
    </nuxt-link>
  </div>
</template>

<style lang="sass" scoped>
.dark-mode .nav-btn
  color: white

.nav-btn
  font-family: 'Barlow', sans-serif
  color: #1e1e1e
  font-size: 1.2rem
  background: transparent
  border: 1px solid transparent
  border-radius: 5px
  cursor: pointer
  padding: 10px 10px 35px 10px
  text-decoration: none

  transition: all 300ms ease-in-out, border 200ms ease

  &:hover
    color: #60cca5

.dark-mode .hovered
  color: #60cca5 !important

.hovered
  color: #287B5D !important

@media (max-width: 480px)
  .nav-btn
    font-size: .5rem

</style>
