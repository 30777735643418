<script setup lang="ts">
import Nav from './components/NavigationBar/Nav';
import MobileNav from "~/components/NavigationBar/MobileNav.vue";

const {isMobile} = useDevice();
const route = useRoute();

const {locale} = useI18n();

// Floating nav needs some extra space except for in the landing page
const usePaddingTop = computed(() => (route.path !== '/' && route.path !== "/en") && !isMobile);
</script>

<template>
  <Html :lang="locale">

  <Head>
    <Title>{{ $t('RefugeName') }}</Title>
    <Meta name="description"
          content="Ορειβατικό Καταφύγιο Καταρράκτη. Το πιο εξοπλισμένο ορειβατικό καταφύγιο στα Τζουμέρκα."/>
  </Head>

  </Html>

  <!--todo change to use layout instead-->
  <MobileNav v-if="isMobile"/>
  <Nav v-else/>
  <NuxtPage :class="{'pad-top': usePaddingTop}"/>
  <Footer/>
</template>

<style lang="sass">
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@400;500&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap')


$dark_background_color: rgb(30, 30, 30)
*
  font-family: 'Barlow', sans-serif
  font-weight: 500
  max-width: 100vw

html, body
  padding: 0
  margin: 0
  overflow-x: hidden

body
  background-color: #fff
  color: rgba(0, 0, 0, 0.8)
  display: flex
  justify-content: center
  transition: 300ms ease-in-out

.dark-mode body
  background-color: $dark_background_color
  color: #ebf4f1

.pad-top
  padding-top: 120px

@media (max-width: 480px)

body
  width: 100vw
</style>
