<script lang="ts" setup="">
import {useColorMode} from "#imports";

const route = useRoute();
const navOpen = ref(false);
const colorMode = useColorMode();

const activeButtonName = ref("system-uicons:menu-hamburger");
const logo_link = "https://res.cloudinary.com/dlct0dnwt/image/upload/v1681256171/refuge/LOGO_AGGLIKO_j6t0ou.svg"

watch(navOpen, _ => {
  activeButtonName.value = navOpen.value ? "material-symbols:close-rounded" : "system-uicons:menu-hamburger";
});

function toggleMobileMenu() {
  navOpen.value = !navOpen.value;
}

function hide() {
  if (navOpen.value)
    navOpen.value = false;
}

function toggleTheme() {
  colorMode.preference = colorMode.preference === "dark" ? "light" : "dark";
}

watch(
    () => route.path,
    () => {
      navOpen.value = false;
    }
)

</script>

<template>
  <div @wheel.prevent @touchmove.prevent @scroll.prevent
       style="display: flex; align-items: center; justify-content: center;">
    <div class="mobile-navigation-bar-container">
      <nuxt-link class="logo-container" to="/" @click="hide">
        <img id="navLogo" alt="Katafygio Logo" :src="logo_link"/>
      </nuxt-link>
      <div aria-label="button" class="hamburger" role="button">
        <Icon :name="activeButtonName" size="50px" style="cursor: pointer;" @click="toggleMobileMenu"/>
      </div>
    </div>


    <Transition name="slide-fade">
      <div v-if="navOpen" class="mobile-menu hide">
        <h1>{{ $t('katarraktisRefuge') }}</h1>
        <div class="row">
          <nuxt-link to="/refuge/booking" @click="hide">{{ $t('Book now') }}</nuxt-link>
          <Icon class="arrow" name="ic:outline-keyboard-double-arrow-right" size="20px"/>
        </div>
        <div class="row">
          <nuxt-link to="/" @click="hide">{{ $t('Home') }}</nuxt-link>
          <Icon class="arrow" name="ic:outline-keyboard-double-arrow-right" size="20px"/>
        </div>

        <div class="row">
          <nuxt-link to="/refuge" @click="hide">{{ $t('Τhe Refuge') }}</nuxt-link>
          <Icon class="arrow" name="ic:outline-keyboard-double-arrow-right" size="20px"/>
        </div>

        <div class="row">
          <nuxt-link to="/services" @click="hide">{{ $t('Services') }}</nuxt-link>
          <Icon class="arrow" name="ic:outline-keyboard-double-arrow-right" size="20px"/>
        </div>

        <div class="row">
          <nuxt-link to="/activities" @click="hide">{{ $t('Activities') }}</nuxt-link>
          <Icon class="arrow" name="ic:outline-keyboard-double-arrow-right" size="20px"/>
        </div>

        <div class="settings">
          <LangSwitcher/>
          <div aria-label="theme-toggle-menu" class="theme-toggler" @click="toggleTheme">
            <div class="circle"></div>
          </div>
        </div>

        <div class="row">
          <DetailsInternalImage style="width: 90vw; height: 200px;" alt="Two people walking down the mountain."
                                link="/refuge/photos" :text="$t('Photos')" quality="30"
                                url="https://res.cloudinary.com/dlct0dnwt/image/upload/v1684614485/refuge/activities/hiking_3_middf1.jpg"/>
        </div>
      </div>
    </Transition>

  </div>
</template>

<style lang="sass" scoped>
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap')
.dark-mode .circle
  background-color: lightgray
  translate: 150%

.circle
  position: relative
  width: 20px
  height: 20px
  border-radius: 50px
  background-color: darkgray
  transition: all 300ms ease-in-out

.dark-mode .theme-toggler
  background-color: rgb(50, 50, 50)
  box-shadow: inset 0 0 5px rgba(20, 20, 20, 0.6)

.theme-toggler
  display: flex
  align-items: center
  width: 50px
  height: 30px
  border-radius: 20px
  background-color: whitesmoke
  padding: 0 5px 0 5px
  cursor: pointer
  box-shadow: inset 0 0 5px rgba(20, 20, 20, 0.2)
  transition: all 300ms ease-in-out
  -webkit-tap-highlight-color: transparent

.settings
  display: flex
  width: 100%
  justify-content: space-around
  align-items: center

.dark-mode .language-selector
  background-color: rgb(30, 30, 30)
  border: 1px solid white
  color: white

.language-selector
  padding: 15px
  background-color: rgb(245, 245, 245)
  width: fit-content
  color: black
  font-size: 16px
  border: 1px solid rgb(40, 40, 40)
  border-radius: 5px
  cursor: pointer
  margin: 0
  transition: all 300ms ease-in-out

h1
  background-color: rgb(245, 245, 245)
  padding: 10px
  border-radius: 10px
  border: 1px solid #d3d3d3
  margin: 10px

.dark-mode h1
  background-color: rgb(30, 30, 30)
  color: white

.row
  display: flex
  justify-content: space-between
  align-items: center
  cursor: pointer

.dark-mode a
  color: white

a
  text-decoration: none
  color: black
  font-size: xx-large
  transition: all 300ms ease-in-out

.slide-fade-enter-active
  transition: all 300ms ease-in-out

.slide-fade-leave-active
  transition: all .8s ease-in-out

.slide-fade-enter-from, .slide-fade-leave-to
  transform: translateX(100px)
  opacity: 0

.dark-mode .mobile-menu
  color: white
  background-color: rgb(30, 30, 30)

.mobile-menu
  position: fixed
  top: 60px
  left: 0
  display: flex
  flex-direction: column
  align-items: center
  height: 100vh
  width: 100vw
  margin: 0
  padding: 0
  z-index: 2
  background-color: white
  font-family: 'Lato', sans-serif
  gap: 10px
  transition: all 300ms ease-in-out

.dark-mode .mobile-navigation-bar-container
  background-color: rgb(30, 30, 30)

.mobile-navigation-bar-container
  width: 100%
  margin: 0
  display: flex
  justify-content: space-between
  align-items: center
  height: 60px
  transition: all 300ms ease-in-out
  background-color: whitesmoke
  padding: 0 16px

.logo-container
  height: 100%
  display: flex
  justify-content: space-between
  align-items: center

  h3
    font-weight: 500
    font-size: x-large
    margin-bottom: 10px
    padding-left: 5px

#navLogo
  height: 80%
</style>
