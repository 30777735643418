import { default as alpineQ5BnCaRWutMeta } from "/app/pages/activities/alpine.vue?macro=true";
import { default as hiking_45trailsRV2vQ8jBE9Meta } from "/app/pages/activities/hiking-trails.vue?macro=true";
import { default as indexrEHaOSkTrQMeta } from "/app/pages/activities/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as newsWdQv0BaSvYMeta } from "/app/pages/news.vue?macro=true";
import { default as approachwBzp8UYgnrMeta } from "/app/pages/refuge/approach.vue?macro=true";
import { default as bookingV34BHKXWLhMeta } from "/app/pages/refuge/booking.vue?macro=true";
import { default as indexhgmjUvh5HbMeta } from "/app/pages/refuge/index.vue?macro=true";
import { default as photosy8QBzmLDYcMeta } from "/app/pages/refuge/photos.vue?macro=true";
import { default as storyyEdJmj08VQMeta } from "/app/pages/refuge/story.vue?macro=true";
import { default as videosUSPrxuySgfMeta } from "/app/pages/refuge/videos.vue?macro=true";
import { default as accommodationR5Mz0R40IsMeta } from "/app/pages/services/accommodation.vue?macro=true";
import { default as indexmjydbmT8M8Meta } from "/app/pages/services/index.vue?macro=true";
import { default as menuvA37nb5ALxMeta } from "/app/pages/services/menu.vue?macro=true";
export default [
  {
    name: "activities-alpine___en",
    path: "/en/activities/alpine",
    component: () => import("/app/pages/activities/alpine.vue")
  },
  {
    name: "activities-alpine___el",
    path: "/activities/alpine",
    component: () => import("/app/pages/activities/alpine.vue")
  },
  {
    name: "activities-alpine___de",
    path: "/de/activities/alpine",
    component: () => import("/app/pages/activities/alpine.vue")
  },
  {
    name: "activities-alpine___fr",
    path: "/fr/activities/alpine",
    component: () => import("/app/pages/activities/alpine.vue")
  },
  {
    name: "activities-alpine___it",
    path: "/it/activities/alpine",
    component: () => import("/app/pages/activities/alpine.vue")
  },
  {
    name: "activities-hiking-trails___en",
    path: "/en/activities/hiking-trails",
    component: () => import("/app/pages/activities/hiking-trails.vue")
  },
  {
    name: "activities-hiking-trails___el",
    path: "/activities/hiking-trails",
    component: () => import("/app/pages/activities/hiking-trails.vue")
  },
  {
    name: "activities-hiking-trails___de",
    path: "/de/activities/hiking-trails",
    component: () => import("/app/pages/activities/hiking-trails.vue")
  },
  {
    name: "activities-hiking-trails___fr",
    path: "/fr/activities/hiking-trails",
    component: () => import("/app/pages/activities/hiking-trails.vue")
  },
  {
    name: "activities-hiking-trails___it",
    path: "/it/activities/hiking-trails",
    component: () => import("/app/pages/activities/hiking-trails.vue")
  },
  {
    name: "activities___en",
    path: "/en/activities",
    component: () => import("/app/pages/activities/index.vue")
  },
  {
    name: "activities___el",
    path: "/activities",
    component: () => import("/app/pages/activities/index.vue")
  },
  {
    name: "activities___de",
    path: "/de/activities",
    component: () => import("/app/pages/activities/index.vue")
  },
  {
    name: "activities___fr",
    path: "/fr/activities",
    component: () => import("/app/pages/activities/index.vue")
  },
  {
    name: "activities___it",
    path: "/it/activities",
    component: () => import("/app/pages/activities/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___el",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___fr",
    path: "/fr",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___it",
    path: "/it",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "news___en",
    path: "/en/news",
    component: () => import("/app/pages/news.vue")
  },
  {
    name: "news___el",
    path: "/news",
    component: () => import("/app/pages/news.vue")
  },
  {
    name: "news___de",
    path: "/de/news",
    component: () => import("/app/pages/news.vue")
  },
  {
    name: "news___fr",
    path: "/fr/news",
    component: () => import("/app/pages/news.vue")
  },
  {
    name: "news___it",
    path: "/it/news",
    component: () => import("/app/pages/news.vue")
  },
  {
    name: "refuge-approach___en",
    path: "/en/refuge/approach",
    component: () => import("/app/pages/refuge/approach.vue")
  },
  {
    name: "refuge-approach___el",
    path: "/refuge/approach",
    component: () => import("/app/pages/refuge/approach.vue")
  },
  {
    name: "refuge-approach___de",
    path: "/de/refuge/approach",
    component: () => import("/app/pages/refuge/approach.vue")
  },
  {
    name: "refuge-approach___fr",
    path: "/fr/refuge/approach",
    component: () => import("/app/pages/refuge/approach.vue")
  },
  {
    name: "refuge-approach___it",
    path: "/it/refuge/approach",
    component: () => import("/app/pages/refuge/approach.vue")
  },
  {
    name: "refuge-booking___en",
    path: "/en/refuge/booking",
    component: () => import("/app/pages/refuge/booking.vue")
  },
  {
    name: "refuge-booking___el",
    path: "/refuge/booking",
    component: () => import("/app/pages/refuge/booking.vue")
  },
  {
    name: "refuge-booking___de",
    path: "/de/refuge/booking",
    component: () => import("/app/pages/refuge/booking.vue")
  },
  {
    name: "refuge-booking___fr",
    path: "/fr/refuge/booking",
    component: () => import("/app/pages/refuge/booking.vue")
  },
  {
    name: "refuge-booking___it",
    path: "/it/refuge/booking",
    component: () => import("/app/pages/refuge/booking.vue")
  },
  {
    name: "refuge___en",
    path: "/en/refuge",
    component: () => import("/app/pages/refuge/index.vue")
  },
  {
    name: "refuge___el",
    path: "/refuge",
    component: () => import("/app/pages/refuge/index.vue")
  },
  {
    name: "refuge___de",
    path: "/de/refuge",
    component: () => import("/app/pages/refuge/index.vue")
  },
  {
    name: "refuge___fr",
    path: "/fr/refuge",
    component: () => import("/app/pages/refuge/index.vue")
  },
  {
    name: "refuge___it",
    path: "/it/refuge",
    component: () => import("/app/pages/refuge/index.vue")
  },
  {
    name: "refuge-photos___en",
    path: "/en/refuge/photos",
    component: () => import("/app/pages/refuge/photos.vue")
  },
  {
    name: "refuge-photos___el",
    path: "/refuge/photos",
    component: () => import("/app/pages/refuge/photos.vue")
  },
  {
    name: "refuge-photos___de",
    path: "/de/refuge/photos",
    component: () => import("/app/pages/refuge/photos.vue")
  },
  {
    name: "refuge-photos___fr",
    path: "/fr/refuge/photos",
    component: () => import("/app/pages/refuge/photos.vue")
  },
  {
    name: "refuge-photos___it",
    path: "/it/refuge/photos",
    component: () => import("/app/pages/refuge/photos.vue")
  },
  {
    name: "refuge-story___en",
    path: "/en/refuge/story",
    component: () => import("/app/pages/refuge/story.vue")
  },
  {
    name: "refuge-story___el",
    path: "/refuge/story",
    component: () => import("/app/pages/refuge/story.vue")
  },
  {
    name: "refuge-story___de",
    path: "/de/refuge/story",
    component: () => import("/app/pages/refuge/story.vue")
  },
  {
    name: "refuge-story___fr",
    path: "/fr/refuge/story",
    component: () => import("/app/pages/refuge/story.vue")
  },
  {
    name: "refuge-story___it",
    path: "/it/refuge/story",
    component: () => import("/app/pages/refuge/story.vue")
  },
  {
    name: "refuge-videos___en",
    path: "/en/refuge/videos",
    component: () => import("/app/pages/refuge/videos.vue")
  },
  {
    name: "refuge-videos___el",
    path: "/refuge/videos",
    component: () => import("/app/pages/refuge/videos.vue")
  },
  {
    name: "refuge-videos___de",
    path: "/de/refuge/videos",
    component: () => import("/app/pages/refuge/videos.vue")
  },
  {
    name: "refuge-videos___fr",
    path: "/fr/refuge/videos",
    component: () => import("/app/pages/refuge/videos.vue")
  },
  {
    name: "refuge-videos___it",
    path: "/it/refuge/videos",
    component: () => import("/app/pages/refuge/videos.vue")
  },
  {
    name: "services-accommodation___en",
    path: "/en/services/accommodation",
    component: () => import("/app/pages/services/accommodation.vue")
  },
  {
    name: "services-accommodation___el",
    path: "/services/accommodation",
    component: () => import("/app/pages/services/accommodation.vue")
  },
  {
    name: "services-accommodation___de",
    path: "/de/services/accommodation",
    component: () => import("/app/pages/services/accommodation.vue")
  },
  {
    name: "services-accommodation___fr",
    path: "/fr/services/accommodation",
    component: () => import("/app/pages/services/accommodation.vue")
  },
  {
    name: "services-accommodation___it",
    path: "/it/services/accommodation",
    component: () => import("/app/pages/services/accommodation.vue")
  },
  {
    name: "services___en",
    path: "/en/services",
    component: () => import("/app/pages/services/index.vue")
  },
  {
    name: "services___el",
    path: "/services",
    component: () => import("/app/pages/services/index.vue")
  },
  {
    name: "services___de",
    path: "/de/services",
    component: () => import("/app/pages/services/index.vue")
  },
  {
    name: "services___fr",
    path: "/fr/services",
    component: () => import("/app/pages/services/index.vue")
  },
  {
    name: "services___it",
    path: "/it/services",
    component: () => import("/app/pages/services/index.vue")
  },
  {
    name: "services-menu___en",
    path: "/en/services/menu",
    component: () => import("/app/pages/services/menu.vue")
  },
  {
    name: "services-menu___el",
    path: "/services/menu",
    component: () => import("/app/pages/services/menu.vue")
  },
  {
    name: "services-menu___de",
    path: "/de/services/menu",
    component: () => import("/app/pages/services/menu.vue")
  },
  {
    name: "services-menu___fr",
    path: "/fr/services/menu",
    component: () => import("/app/pages/services/menu.vue")
  },
  {
    name: "services-menu___it",
    path: "/it/services/menu",
    component: () => import("/app/pages/services/menu.vue")
  }
]