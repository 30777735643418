import revive_payload_client_vsMObDfwxP from "/app/node_modules/.pnpm/nuxt@3.13.2_eslint@9.13.0_rollup@4.24.0_sass@1.80.4_typescript@4.9.5_vite@5.4.10/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_X8i2lufcE4 from "/app/node_modules/.pnpm/nuxt@3.13.2_eslint@9.13.0_rollup@4.24.0_sass@1.80.4_typescript@4.9.5_vite@5.4.10/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_0wdd1aAI9i from "/app/node_modules/.pnpm/nuxt@3.13.2_eslint@9.13.0_rollup@4.24.0_sass@1.80.4_typescript@4.9.5_vite@5.4.10/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_PSbK7ZId6P from "/app/node_modules/.pnpm/nuxt@3.13.2_eslint@9.13.0_rollup@4.24.0_sass@1.80.4_typescript@4.9.5_vite@5.4.10/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_ITbLm4a5YG from "/app/node_modules/.pnpm/nuxt@3.13.2_eslint@9.13.0_rollup@4.24.0_sass@1.80.4_typescript@4.9.5_vite@5.4.10/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_GbZJxliu2L from "/app/node_modules/.pnpm/nuxt@3.13.2_eslint@9.13.0_rollup@4.24.0_sass@1.80.4_typescript@4.9.5_vite@5.4.10/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_bp75sNf1kJ from "/app/node_modules/.pnpm/nuxt@3.13.2_eslint@9.13.0_rollup@4.24.0_sass@1.80.4_typescript@4.9.5_vite@5.4.10/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_INHlXgIBvI from "/app/node_modules/.pnpm/nuxt@3.13.2_eslint@9.13.0_rollup@4.24.0_sass@1.80.4_typescript@4.9.5_vite@5.4.10/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_Fu6JFpmMkx from "/app/node_modules/.pnpm/nuxt-easy-lightbox@1.0.2_rollup@4.24.0_vue@3.5.12/node_modules/nuxt-easy-lightbox/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_IfcldbFAwz from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_rollup@4.24.0_vue@3.5.12/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_rzev2x9c91 from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_rollup@4.24.0_vue@3.5.12/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_wy0B721ODc from "/app/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_client_miGlWcbtBl from "/app/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_rollup@4.24.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
export default [
  revive_payload_client_vsMObDfwxP,
  unhead_X8i2lufcE4,
  router_0wdd1aAI9i,
  payload_client_PSbK7ZId6P,
  navigation_repaint_client_ITbLm4a5YG,
  check_outdated_build_client_GbZJxliu2L,
  chunk_reload_client_bp75sNf1kJ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_INHlXgIBvI,
  plugin_Fu6JFpmMkx,
  switch_locale_path_ssr_IfcldbFAwz,
  i18n_rzev2x9c91,
  plugin_wy0B721ODc,
  plugin_client_miGlWcbtBl
]