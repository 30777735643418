import {RefugeImage} from "~/types/RefugeImage";

export class NavDetails{
    public header: string;
    public links: string[];

    public imageHeaders: string[];
    public imageLinks: string[];
    public imageRedirects: string[];

    public leftList: string[];
    public rightList: RefugeImage[] = [];

    public secondLeftList?: string[];
    public secondHeader?: string;
    public secondListLinks?: string[];

    constructor(header: string, leftList: string[],  links: string[], imageHeaders: string[], imageLinks: string[],  imageRedirects: string[], secondHeader?: string, secondLeftList?: string[], secondListLinks?: string[]) {
        this.header = header;
        this.leftList = leftList;
        this.links = links;

        this.imageHeaders = imageHeaders;
        this.imageLinks = imageLinks;
        this.imageRedirects = imageRedirects;

        this.secondLeftList = secondLeftList;
        this.secondHeader = secondHeader;
        this.secondListLinks = secondListLinks;
    }
}
