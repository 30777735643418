<template>
  <div aria-label="language menu hover" class="buttonContainer">
    <div class="language-toggler">
      <Icon name="ph:globe-light" preload size="30px"/>
      <p>{{ $t('currentLanguage') }}</p>
    </div>
    <div aria-label="dropdown menu" class="dropdown-content">
      <NuxtLink v-for="locale in availableLocales" :key="locale.code" :to="switchLocalePath(locale.code)"
                class="language-selector">
        {{ locale.name }}
      </NuxtLink>
    </div>
  </div>
</template>

<script setup>
const {locale, locales} = useI18n();
const switchLocalePath = useSwitchLocalePath();

const availableLocales = computed(() => {
  return locales.value.filter(i => i.code !== locale.value);
});

</script>

<style scoped lang="sass">
.buttonContainer
  position: relative
  width: fit-content
  display: inline-block
  font-size: 20px

/* Show the dropdown menu on hover */
.buttonContainer:hover .dropdown-content
  display: flex
  flex-direction: column
  z-index: 2

.dropdown-content
  display: none
  position: absolute
  background-color: white
  left: 50%
  transform: translate(-50%, 0)
  width: fit-content
  min-width: 10ch
  border: 1px solid rgba(0, 0, 0, 0.2)

.dark-mode .language-selector
  color: white

  &:hover
    background-color: rgba(255, 255, 255, 0.2)

.language-selector
  padding: 15px
  background-color: transparent
  color: black
  font-size: 1.1rem
  border: none
  border-bottom: 1px solid rgba(0, 0, 0, 0.2)
  cursor: pointer
  margin: 0
  transition: all 300ms ease
  text-decoration: none

  &:hover
    background-color: rgba(0, 0, 0, 0.1)

.dark-mode .language-toggler
  color: lightgray
  transition: all 300ms ease-in-out

.language-toggler
  cursor: pointer
  padding: 5px
  display: flex
  width: fit-content
  justify-content: center
  align-items: center

.dark-mode .dropdown-content
  color: white
  background-color: #303030


</style>