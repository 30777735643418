<script lang="ts" setup="">
import {NavDetails} from "~/types/NavDetails";

const localePath = useLocalePath();

const props = defineProps({
  details: {type: NavDetails, required: true},
});

//todo this whole thing needs to be rewritten honestly. should be 2 components that handle different things
</script>

<template>
  <div class="details-view-container">
    <div id="left" class="aligned-details" :class="{'single' : !details.secondLeftList} ">
      <div class="full-height">
        <ul>
          <li class="list-header">{{ $t(details.header) }}</li>
          <li v-for="(route, index) in details.links" :key=route>
            <NuxtLink :to="localePath(route)">
              {{ $t(details.leftList[index]) }}
            </NuxtLink>
          </li>
        </ul>
      </div>

      <div v-if="details.secondLeftList && details.secondHeader" class="full-height">
        <ul>
          <li class="list-header">{{ $t(details.secondHeader) }}</li>
          <li v-for="(route, index) in details.secondListLinks" :key=route>
            <nuxt-link :to="localePath(route)">
              {{ $t(details.secondLeftList[index]) }}
            </nuxt-link>
          </li>
        </ul>
      </div>

    </div>

    <div id="right" class="aligned-details">
      <DetailsInternalImage v-for="(imageLink, index) in details.imageLinks" :key="imageLink"
                            :link="details.imageRedirects[index]" :text="$t(details.imageHeaders[index])"
                            :url="imageLink" alt="hello"
                            :use-half="true"
                            quality="30"/>
    </div>

  </div>
</template>

<style lang="sass" scoped>
.list-header
  font-size: 1.8rem
  font-weight: bold
  margin: 0

.full-height
  height: 100%
  width: fit-content
  display: flex
  flex-direction: column
  justify-content: flex-start

.dark-mode a
  color: white

a
  font-size: 1.4rem
  font-weight: normal
  text-decoration: none
  color: black
  transition: all 150ms ease

  &:hover
    color: #60cca5

li
  margin: 10px 0

ul
  list-style-type: none
  padding: 0
  margin: 0

h1
  font-size: 1.5rem
  font-weight: bold
  margin-top: 0
  cursor: default

.details-view-container
  display: flex
  justify-content: space-evenly
  height: 90%
  width: 95%
  gap: 10px
  transition: all 150ms ease-in-out
  z-index: 2

.aligned-details
  display: flex
  justify-content: center
  gap: 20px
  width: 50% !important
  height: 95%
  z-index: 2

  .details-side-image-container
    display: flex

.single
  justify-content: center
  width: 100%
  align-items: center

#left
  width: 50%
  height: 100%

#right
  width: 50%
  height: 100%
</style>
