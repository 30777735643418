import routerOptions0 from "/app/node_modules/.pnpm/nuxt@3.13.2_eslint@9.13.0_rollup@4.24.0_sass@1.80.4_typescript@4.9.5_vite@5.4.10/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/app/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}