import { defineNuxtPlugin } from '#app/nuxt'
import { LazyApproachMapButton, LazyDestination, LazyDetailsInternalImage, LazyDetailsView, LazyFacebookContact, LazyFooter, LazyHamburger, LazyInternalRedirectButton, LazyLangSwitcher, LazyModalsSinglePhotoModal, LazyNavigationBarDropdownNavButton, LazyNavigationBarMobileNav, LazyNavigationBarNav, LazyNavigationBarNavButton, LazyUtilitiesLoadingSpinner, LazyWeatherWidget, LazyCldImage, LazyCldMediaLibrary, LazyCldOgImage, LazyCldProductGallery, LazyCldUploadButton, LazyCldUploadWidget, LazyCldVideoPlayer, LazyIcon, LazyIconCSS } from '#components'
const lazyGlobalComponents = [
  ["ApproachMapButton", LazyApproachMapButton],
["Destination", LazyDestination],
["DetailsInternalImage", LazyDetailsInternalImage],
["DetailsView", LazyDetailsView],
["FacebookContact", LazyFacebookContact],
["Footer", LazyFooter],
["Hamburger", LazyHamburger],
["InternalRedirectButton", LazyInternalRedirectButton],
["LangSwitcher", LazyLangSwitcher],
["ModalsSinglePhotoModal", LazyModalsSinglePhotoModal],
["NavigationBarDropdownNavButton", LazyNavigationBarDropdownNavButton],
["NavigationBarMobileNav", LazyNavigationBarMobileNav],
["NavigationBarNav", LazyNavigationBarNav],
["NavigationBarNavButton", LazyNavigationBarNavButton],
["UtilitiesLoadingSpinner", LazyUtilitiesLoadingSpinner],
["WeatherWidget", LazyWeatherWidget],
["CldImage", LazyCldImage],
["CldMediaLibrary", LazyCldMediaLibrary],
["CldOgImage", LazyCldOgImage],
["CldProductGallery", LazyCldProductGallery],
["CldUploadButton", LazyCldUploadButton],
["CldUploadWidget", LazyCldUploadWidget],
["CldVideoPlayer", LazyCldVideoPlayer],
["Icon", LazyIcon],
["IconCSS", LazyIconCSS],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
