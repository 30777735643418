<script lang="ts" setup="">
const localePath = useLocalePath();

const props = defineProps(
    {
      text: {type: String, required: true},
      redirect: {type: String, required: true},
      icon: {type: String, required: false}
    }
)
</script>

<template>
  <nuxt-link :to="localePath(redirect)" class="internal-redirect-btn">
    <Icon v-if="icon" :name="icon" color="black" size="2rem"/>
    {{ text }}
  </nuxt-link>
</template>

<style lang="sass" scoped>
$dark_background_color: rgb(30, 30, 30)
$hover_dark_gray: rgb(40, 40, 40)

.dark-mode .internal-redirect-btn
  border: 1px solid white
  color: white

  &:hover
    background-color: white
    color: black
    border: 1px solid black

.internal-redirect-btn
  display: flex
  justify-content: center
  align-items: center
  padding: 0 1rem
  font-weight: bold
  height: 4rem
  cursor: pointer
  transition: all 300ms ease-in-out
  border: 1px solid black
  text-decoration: none
  color: black
  width: fit-content
  min-width: 10ch

  &:hover
    color: white
    background-color: $dark_background_color


</style>
